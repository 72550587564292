((global) => {
  /**
   * @class SessionCheck
   * @description This class is for handling the session check api call
   * @file global/js/modules/session-check.js
   */
  class SessionCheck {
    /**
     * Get the session status using the apiu URL ie /content/act/my_vertu.p
     * @param {string} SESSION_API_URL - The API URL to call for the session check
     * @param {string} checkType - The check we want to call - extend or check
     */
    static checkSession(SESSION_API_URL, checkType) {

      let action;

      if (checkType === 'extend') {
        action = 'SessionActive';
      } else if (checkType === 'check') {
        action = 'SessionCheck';
      } else {
        global.vm.debug.warn('SessionCheck:checkSession - checkType value needs to be either extend or check.');
      }

      if (SESSION_API_URL && SESSION_API_URL !== '') {
        return axios.get(`${SESSION_API_URL}`, {
          params: {
            formAction: action,
            myFormat: 'Angular',
          },
        }).then((res) => res.data)
          .catch(err => {
            window.vm.debug.error(err);
          });
      }

      global.vm.debug.warn('SessionCheck:checkSession - API URL AND checkType required to check the session.');
      return Promise.resolve(null);
    }
  }

  global.SessionCheck = SessionCheck;
})(window);
