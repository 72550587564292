/*
 Copyright 2014 Google Inc. All rights reserved.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

((window) => {

  if (window.cookieChoices) {
    return window.cookieChoices;
  }

  const { document } = window;
  // IE8 does not support textContent, so we should fallback to innerText.
  const supportsTextContent = 'textContent' in document.body;

  const cookieChoices = (() => {

    const cookieName = 'displayCookieConsent';
    const cookieConsentClass = 'cookie-bar';
    const dismissBtnClass = 'js-cookie-bar-dismiss';
    const cookieBarBtnClass = 'cookie-bar__btn';

    const _createHeaderElement = (cookieText, dismissText, linkText, linkHref) => {

      const cookieConsentElement = document.createElement('div');
      cookieConsentElement.classList.add(cookieConsentClass);
      cookieConsentElement.appendChild(_createConsentText(cookieText));
      cookieConsentElement.appendChild(_createdismissBtn(dismissText));

      if (!!linkText && !!linkHref) {
        cookieConsentElement.appendChild(_createInformationLink(linkText, linkHref));
      }
      return cookieConsentElement;
    };

    const _setElementText = (element, text) => {
      if (supportsTextContent) {
        element.textContent = text;
      } else {
        element.innerText = text;
      }
    };

    const _createConsentText = (cookieText) => {
      const consentText = document.createElement('div');
      consentText.classList.add('cookie-bar__text');
      _setElementText(consentText, cookieText);
      return consentText;
    };

    const _createdismissBtn = (dismissText) => {
      const dismissBtn = document.createElement('button');
      _setElementText(dismissBtn, dismissText);
      dismissBtn.classList.add(cookieBarBtnClass, `${cookieBarBtnClass}--first`, 'btn', 'btn--primary', dismissBtnClass);
      return dismissBtn;
    };

    const _createInformationLink = (linkText, linkHref) => {
      const infoLink = document.createElement('a');
      _setElementText(infoLink, linkText);
      infoLink.href = linkHref;
      infoLink.target = '_blank';
      infoLink.classList.add(cookieBarBtnClass, 'btn', 'btn--outline');
      return infoLink;
    };

    const _dismissBtnClick = () => {
      _saveUserPreference();
      _removeCookieConsent();
      return false;
    };

    const _showCookieConsent = (cookieText, dismissText, linkText, linkHref) => {
      if (_shouldDisplayConsent()) {
        _removeCookieConsent();
        const consentElement = _createHeaderElement(cookieText, dismissText, linkText, linkHref);
        const fragment = document.createDocumentFragment();
        fragment.appendChild(consentElement);
        document.body.appendChild(fragment.cloneNode(true));
        document.querySelector(`.${dismissBtnClass}`).onclick = _dismissBtnClick;
      }
    };

    const showCookieConsentBar = (cookieText, dismissText, linkText, linkHref) => {
      _showCookieConsent(cookieText, dismissText, linkText, linkHref, false);
    };

    const showCookieConsentDialog = (cookieText, dismissText, linkText, linkHref) => {
      _showCookieConsent(cookieText, dismissText, linkText, linkHref, true);
    };

    const _removeCookieConsent = () => {
      const cookieChoiceElement = document.querySelector(`.${cookieConsentClass}`);
      if (cookieChoiceElement != null) {
        cookieChoiceElement.parentNode.removeChild(cookieChoiceElement);
      }
    };

    const _saveUserPreference = () => {
      // Set the cookie expiry to one year after today.
      const expiryDate = new Date();
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
      document.cookie = `${cookieName}=y; path=/; expires=${expiryDate.toGMTString()}`;
    };

    // Display the header only if the cookie has not been set.
    const _shouldDisplayConsent = () => !document.cookie.match(new RegExp(`${cookieName}=([^;]+)`));

    const exports = {};
    exports.showCookieConsentBar = showCookieConsentBar;
    exports.showCookieConsentDialog = showCookieConsentDialog;
    return exports;
  })();

  // Our bespoke code
  vm.onload(() => { // Added instead of addEventlistener because of IE8 issue
    // eslint-disable-next-line max-len
    cookieChoices.showCookieConsentBar('Our websites uses cookie technology which helps us bring you the best possible experience when you visit this website.',
      'OK', 'Learn more', '/cookie-policy/');
  });

  window.cookieChoices = cookieChoices;
  return cookieChoices;
})(window);
