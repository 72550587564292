/**
 * Vertu motors debug is adding a debugger to the vm library
 * for debugging purposes
 */
((window, vm) => {
  class Debugger {
    constructor() {
      const isDebug = /debug=true/.test(window.location.href) || window.location.protocol === 'http:';

      this.debug = {};
      for (const m in window.console) {
        if (typeof window.console[m] === 'function') {
          if (isDebug) {
            this.debug[m] = window.console[m].bind(window.console);
          } else {
            this.debug[m] = () => { };
          }
        }
      }
      return this.debug;
    }
  }

  Object.defineProperty(vm, 'debug', {
    value: new Debugger(),
    writable: false,
  });
})(window, vm);
