// Scroll to anchor point with offset - v1 (09/10/2020)

const getIDFromURL = () => {
  const url = window.location.href;
  const targetElement = url.substring(url.lastIndexOf('#') + 1);
  // find that element in the HTML
  const targetElementID = document.getElementById(targetElement);
  // check to see if it exsist - return "" if not
  if (targetElementID == null || targetElementID == -1) {
    // console.log("Error - cant find the target element from URL!");
    return "";
  }
  // return the HTML element with the specific ID
  return targetElementID;
};

/**
* Get the height of any given element - return 0 if the element can't be found
* @param {*} elementClassName
*/
const getElementHeight = (elementClassName) => {
  let i;
  let elementToGet;
  let elToGetHeight;
  // app-search-filter is a tag and the rest are classes so have to be gathered differently
  if (elementClassName == "app-search-filter") {
    elementToGet = document.getElementsByTagName('app-search-filter');
  } else {
    // eslint-disable-next-line
    if (elementClassName == "js-primary-nav") {
      elementToGet = document.querySelectorAll('.js-primary-nav');
      if (elementToGet.length == 0) {
        console.log(`Error - can't find the element with class name: ${elementClassName}`);
        return 0;
      }
      elToGetHeight = elementToGet[0].offsetHeight + 35;
      return elToGetHeight;
    }
    elementToGet = document.getElementsByClassName(elementClassName);
  }

  // find the specific one that was requested
  for (i = 0; i < elementToGet.length; i++) {
    // check each one and see if it matches - if it does get the height and return
    if (elementToGet[i].className == elementClassName) {
      elToGetHeight = elementToGet[i].offsetHeight;
      return elToGetHeight;
    }
  }
  // do this if it can't find the element - return 0 as not to break the calculation
  console.log(`Error - can't find the element with class name: ${elementClassName}`);
  return 0;
};

const removeHash = () => {
  let scrollV = window.location;
  let scrollH = window.location;
  const loc = window.location;
  // eslint-disable-next-line no-restricted-globals
  if ("pushState" in history) {
    // eslint-disable-next-line no-restricted-globals
    history.pushState("", document.title, loc.pathname + loc.search);
  } else {
    // Prevent scrolling by storing the page's current scroll offset
    scrollV = document.body.scrollTop;
    scrollH = document.body.scrollLeft;
    loc.hash = "";
    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scrollV;
    document.body.scrollLeft = scrollH;
  }
};

/**
 * Function to go to anchor points from on click of the footer links - For the contact form
 * @param {*} targetID
 */
// eslint-disable-next-line
const scrollToTargetIDFromEl = (targetID) => {

  const targetToScrollTo = document.getElementById(targetID);
  const primeNavHeight = getElementHeight('js-primary-nav');
  const secNavHeight = getElementHeight('secondary-nav');
  const searchBarHeight = getElementHeight('app-search-filter');
  const tabsHeight = getElementHeight('tabs__navigation tabs--clean tabs--scroll-mobile tabs--with-border-bottom') + 20;
  console.log(primeNavHeight);
  // calculate offsets
  const yOffSet = -(primeNavHeight + secNavHeight + searchBarHeight + tabsHeight);
  const y = targetToScrollTo.getBoundingClientRect().top + window.pageYOffset + yOffSet;
  // scroll to the target anchor point
  window.scrollTo({ top: y, behavior: 'smooth' });
};

// eslint-disable-next-line
const scrollToAnchorPoint = () => {
  // get the ID from the URL and store the ID
  const targetToScrollTo = getIDFromURL();
  // remove the hash as not to break the code for the next click event
  removeHash();
  // check to return value of the ID
  if (targetToScrollTo == "") {
    console.log("Can't scroll as ID can't be found");
  } else {
    let offSetMob = 0;
    // Iphone does not scroll all the way so look to see if the current device is an Iphone or not
    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    // if the current device is an Iphone apply offset, if not, keep as 0
    if (isIOS) {
      offSetMob = 50;
    }
    // get the heights of all the sticky elements
    const primeNavHeight = getElementHeight('js-primary-nav');
    const secNavHeight = getElementHeight('site-secondary-nav-container');
    const searchBarHeight = getElementHeight('app-search-filter');
    const tabsHeight = getElementHeight('tabs__navigation tabs--clean tabs--scroll-mobile tabs--with-border-bottom') + 20;
    console.log(primeNavHeight);
    // calculate offsets
    const yOffSet = -(primeNavHeight + secNavHeight + searchBarHeight + offSetMob + tabsHeight);
    const y = targetToScrollTo.getBoundingClientRect().top + window.pageYOffset + yOffSet;
    // scroll to the target anchor point
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};
