const LL = (() => {
  class LazyLoader {
    constructor() {
      this.config = { childList: true, subtree: true };
      this.mutationObserver = new MutationObserver(this.checkForLazyElementsOnMutation.bind(this));
      this.lazyElementObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const lazyElement = entry.target;
              this.loadElement(lazyElement).then(() => {
                this.lazyElementObserver.unobserve(lazyElement);
              });
            }
          });
        },
        { rootMargin: '0px 0px 200px 0px' },
      );
    }

    checkForLazyElements() {
      const lazyElements = [].slice.call(document.querySelectorAll('.lazyload'));
      if (lazyElements.length < 0) return;
      lazyElements.forEach((lazyElement) => {
        this.lazyElementObserver.observe(lazyElement);
      });
    }

    checkForLazyElementsOnMutation(mutationList) {
      let check = false;
      mutationList.forEach((mutation) => {
        if (mutation.target.querySelectorAll('.lazyload').length > 0) {
          check = true;
        }
      });
      if (check) this.checkForLazyElements();
    }

    loadElement(lazyElement) {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve) => {
        if (lazyElement.classList.contains('lazyloaded')) return resolve(lazyElement);
        if (lazyElement.dataset.bgimg) {
          const img = new Image();
          const parts = lazyElement.dataset.bgimg.split(' ');
          [img.src] = parts;
          img.onload = () => {
            lazyElement.style.background = `url(${img.src})${parts[1] ? ` ${parts[1]}` : ''}`;
            fadeIn(lazyElement);
            return resolve(lazyElement);
          };
        } else {
          if (lazyElement.dataset.src) lazyElement.src = lazyElement.dataset.src;
          if (lazyElement.dataset.srcset) lazyElement.srcset = lazyElement.dataset.srcset;

          let img;
          if (lazyElement.constructor === HTMLSourceElement) {
            img = lazyElement.parentElement.querySelector('img');
          }
          if (img) {
            if (img.onload) {
              fadeIn(lazyElement);
              return resolve(lazyElement);
            }
            img.onload = () => {
              fadeIn(lazyElement);
              return resolve(lazyElement);
            };
          } else {
            if (lazyElement.onload) {
              fadeIn(lazyElement);
              return resolve(lazyElement);
            }
            lazyElement.onload = () => {
              fadeIn(lazyElement);
              return resolve(lazyElement);
            };
          }
        }
      });
    }

    preloadChildElements(target) {
      const lazyElements = [].slice.call(target.querySelectorAll('.lazyload'));
      if (lazyElements.length <= 0) return Promise.resolve([].slice.call(target.querySelectorAll('.lazyloaded')));
      const promises = [];
      lazyElements.forEach((lazyElement) => {
        promises.push(this.loadElement(lazyElement));
      });
      return Promise.all(promises);
    }

    registerObserver(target) {
      this.checkForLazyElements();
      this.mutationObserver.observe(target || document.body, this.config);
    }
  }

  // // Helpers

  function fadeIn(element) {
    if ('complete' in element && !element.complete) {
      let interval = requestAnimationFrame(function loop() {
        if (element.complete) {
          cancelAnimationFrame(interval);
          removeLazyClass(element);
        } else {
          interval = requestAnimationFrame(loop);
        }
      });
    } else {
      removeLazyClass(element);
    }
  }

  function removeLazyClass(element) {
    const blockNav = element.parentElement.parentElement;
    element.classList.add('lazyloaded');
    element.classList.remove('lazyload');
    if (
      blockNav.classList.contains('hover__img--zoom')
      && !blockNav.classList.contains('block-nav__item--with-border-radius')
      && blockNav.querySelectorAll('.lazyload').length === 0
    ) {
      blockNav.classList.add('block-nav__item--with-border-radius');
    }
  }

  return new LazyLoader();
})();

vm.onload(() => LL.registerObserver());
