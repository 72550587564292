(function (window, document) {
  // Check if passive option is supported to improve scrolling performance
  let passiveIfSupported = false;

  try {
    window.addEventListener("test", null, Object.defineProperty({}, "passive", { get: function () { passiveIfSupported = { passive: false }; } }));
  } catch (err) { }

  // Create the back to top element and add styles and inner html
  const backTop = document.createElement('div');
  backTop.id = 'back-top';
  backTop.className = 'back-to-top';
  backTop.innerHTML = '<span><svg class="icon back-to-top__icon" aria-hidden="true" focusable="false"><use xlink:href="#globalArrowUp"></use></svg></span>';
  backTop.style.display = 'none';

  // Add the element to the page
  document.querySelector('.site-container').insertAdjacentElement('afterBegin', backTop);

  // Add scroll event listener to window to fade in/out the button
  window.addEventListener('scroll', function () {
    backTop.style.display = window.pageYOffset < 40 ? 'none' : 'block';
    if ((window.pageYOffset / 3) <= 100) {
      backTop.style.opacity = ((window.pageYOffset / 3) / 100).toFixed(2);
    } else {
      backTop.style.opacity = 1;
    }
  }, passiveIfSupported);

  // Add click listener to scroll back to top
  backTop.onclick = function (e) {
    e.preventDefault();
    window.scroll({ top: 0, behavior: 'smooth' });
  };

})(window, document);
