var ModalEffects = (function () {

    // Select the overlay
    var overlay;

    function init() {
        // Initialize the overlay
        overlay = document.querySelector('.modal__overlay');

        [].slice.call(document.querySelectorAll('.modal__trigger')).forEach(function (trigger) {
            addModalTrigger(trigger);
        });
    }

    /**
     * Close the modal by it's id
     * @param {string} id - The modal's id
     * @param {function} cb - If you need to call a callback when modal closes
     */
    function closeModal(id, cb) {
        var modal = document.querySelector('#' + id);

        // If the modal was found then close the modal
        if (modal) {
            modal.classList.remove('modal--is-visible');
            modal.style.zIndex = -1;
            overlay.style.zIndex = -1;
            document.body.classList.remove('modal--is-open');

            // show widgets on page..
            showWidgets();

    

            // Remove event listener from the overlay
            overlay.onclick = null;
            document.onkeydown = null;

            // If a cb is passed then call it with a true argument to identify the modal is closed
            if (cb) {
                cb(true);
            }

            // If pause all players is defined to stop playing the video billboard
            if (typeof pauseAllPlayers !== 'undefined') {
                pauseAllPlayers();
            }
        }
    }

    /**
     * Open the modal by it's id
     * @param {string} id - The modal's id
     * @param {function} cb - If you need to call a callback when modal closes
     */
    function openModal(id, cb) {
        var modal = document.querySelector('#' + id);

        if (modal) {
            var close = modal.querySelector('.modal__close');
            modal.insertAdjacentElement('afterend', overlay);

            function closeModalHandler(event) {
                event.preventDefault();
                event.stopPropagation();
                closeModal(modal.id, cb);
            }

           

            // Open the modal
            modal.classList.add('modal--is-visible');
            modal.style.zIndex = 100002;
            overlay.style.zIndex = 100001;
            document.body.classList.add('modal--is-open');

            // hide widgets on page....
            hideWidgets();

            // Add a click listener to the overlay to close the modal
            overlay.onclick = closeModalHandler;

            // Add a click listener to the close button
            if (close) {
                close.onclick = closeModalHandler;
            }

            // Add a key down event listener to close the modal with ESC key
            document.onkeydown = function (event) {
                if (event.keyCode === 27) {
                    closeModal(modal.id, cb);
                }
            };
        }
    }

    /**
     * Add an element to trigger the modal opening
     * @param {Element} trigger - The element to open the modal
     * @param {function} cb - If you need to call a callback when modal closes
     */
    function addModalTrigger(trigger, cb) {
        // if trigger is not provided return
        if (!trigger) {
            return;
        }

        // Select the trigger's modal and close button
        var modal = document.querySelector('#' + trigger.getAttribute('data-modal'));

        // Check if the modal was found to avoid errors
        if (modal) {
            var close = modal.querySelector('.modal__close');


            function closeModalHandler(event) {
                event.preventDefault();
                event.stopPropagation();
                closeModal(modal.id, cb);
            }

            // Add a click listener to open the modal
            trigger.addEventListener('click', function () {
                modal.insertAdjacentElement('afterend', overlay);

                modal.classList.add('modal--is-visible');
                modal.style.zIndex = 100002;
                overlay.style.zIndex = 100001;
                document.body.classList.add('modal--is-open');

                // hide widgets on page....
                hideWidgets();

                // Add a click listener to the overlay to close the modal
                overlay.onclick = closeModalHandler;

                // Add a key down event listener to close the modal with ESC key
                document.onkeydown = function (event) {
                    if (event.keyCode === 27) {
                        closeModal(modal.id, cb);
                    }
                };
            });

            // Add a click listener to the close button
            if (close) {
                close.onclick = closeModalHandler;
            }
        }
    }

    function hideWidgets() {
        var widget = document.querySelector('.globalClass_ET');
        if (widget) {
            widget.classList.add('hide__default');
        }
    }

    function showWidgets() {
        var widget = document.querySelector('.globalClass_ET');
        if (widget) {
            widget.classList.remove('hide__default');
        }
    }

    vm.onload(function () {
        // Intialize the modal
        init();
    });

    return {
        closeModal: closeModal,
        openModal: openModal,
        addModalTrigger: addModalTrigger,
        hideWidgets: hideWidgets,
        showWidgets: showWidgets,
    };

})();
