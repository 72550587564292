
(function (window, document) {
  const galleryObjs = [];
  class Carousel {
    constructor(carousel, containsDots = true, containsArrows = true, interval = 0) {
      // initialise all member variables
      this.containsDots = containsDots;
      this.containsArrows = containsArrows;
      this.carousel = carousel;
      this.carouselSlidesContainersBool = false;
      this.carouselSlidesContainers = carousel.querySelectorAll('.carousel__slides-container');
      if (this.carouselSlidesContainers.length > 0) {
        this.carouselSlidesContainersBool = true;
        this.currentSlidePosition = 0;
        this.slides = this.carouselSlidesContainers[0].querySelectorAll('.carousel__slide');
        this.images = this.carouselSlidesContainers[0].querySelectorAll('.carousel__image');
      } else {
        this.slides = this.carousel.querySelectorAll('.carousel__slide');
        this.images = this.carousel.querySelectorAll('.carousel__image');
      }
      this.length = this.slides.length;
      this.imagesLength = this.images.length;
      this.dotsWrapper = this.carousel.querySelector('.carousel__dots-wrapper');
      this.dots = [];
      this.imageSrcs = [];
      this.currIndex = 0;
      this.expectedIndex = 0;
      this.lastIndex = 0;
      this.automaticSlider = 0;
      this.automaticTimeInterval = interval;
      this.swiping = false;
      this.loading = false;

      // if there is no slides, hide closest parent section
      if (!this.length || this.length === 0) {
        this.carousel.closest('section').style.display = 'none';
      } else {
        // If carousel slides container is not found
        if (this.carouselSlidesContainersBool === false) {
          this.slides[0].classList.add('carousel__slide--current');
          this.slides[0].classList.remove('hide--collapse');
        }
        // make sure to disable html dragging of the slides to allow for swiping
        this.carousel.ondragstart = function () { return false; };
        for (let i = 0; i < this.imagesLength; i++) {
          this.slides[i].ondragstart = function () { return false; };
          this.images[i].ondragstart = function () { return false; };
          this.images[i].setAttribute('data-banner-position', i);
        }

        if (this.length > 1) {
          if (containsArrows) {
            this.carousel.querySelector('.carousel__arrow--left').style.display = 'block';
            this.carousel.querySelector('.carousel__arrow--right').style.display = 'block';
          }
          this.pushImageSrcs();
          if (containsDots && this.dotsWrapper) {
            this.buildDots();
            this.setDot();
          }
        }
        // initialise first image
        LL.preloadChildElements(this.slides[this.currIndex]);
        if (interval !== 0) {
          this.runInterval(1);
        }
      }
    }

    runInterval(nextSlide) {
      const self = this;
      let newIndex = this.getIndex(nextSlide);
      this.preloadSlide(newIndex);
      this.automaticSlider = setInterval(() => {
        newIndex = self.getIndex(self.currIndex + 1);
        self.scrollHandler(newIndex);
        newIndex = self.getIndex(newIndex + 1);
        self.preloadSlide(newIndex);
      }, self.automaticTimeInterval);
    }


    pushImageSrcs() {
      for (let i = 0; i < this.length; i++) {
        this.imageSrcs.push('');
      }
    }


    buildDots() {
      const fragment = document.createDocumentFragment();
      // Create each navigation dot within the dot wrapper
      for (let i = 0; i < this.length; i++) {
        const dot = document.createElement('li');
        dot.setAttribute('data-slide', i);
        dot.classList.add('carousel__dot');
        fragment.appendChild(dot);
        this.dots.push(dot);
      }
      this.dotsWrapper.appendChild(fragment);
    }

    setDot() {
      if (this.containsDots) {
      // Set last dot as inactive and current as active
        this.dots[this.lastIndex].classList.remove('active');
        this.dots[this.currIndex].classList.add('active');
      }
    }

    preloadSlide(index) {
      const self = this;
      if (!this.imageSrcs[index] || this.imageSrcs[index] === '') {
        this.imageSrcs[index] = 'loading';
        LL.preloadChildElements(this.slides[this.getIndex(index)])
          .then(() => {
            self.imageSrcs[index] = 'loaded';
            if (self.expectedIndex !== self.currIndex && self.expectedIndex === index) {
              self.scrollHandler(self.getIndex(index));
            }
          });
      }
    }

    // update index and display current slide
    scroll(index) {
      // Remove loading overlay
      this.carousel.querySelector('.carousel__overlay').classList.remove('carousel__overlay--visible');
      this.loading = false;

      // Set current index
      this.currIndex = index;

      // If carousel slide container exists
      if (this.carouselSlidesContainersBool) {
        // If current index is greater than last index
        if (this.currIndex > this.lastIndex) {
          // If going from first index to last
          if (this.lastIndex === 0 && this.currIndex === (this.length - 1)) {
            this.currentSlidePosition = ((this.length * 100) - 100);
          } else {
            this.currentSlidePosition = (this.currIndex * 100);
          }
          // Else if current index is less than last index
        } else if (this.currIndex < this.lastIndex) {
          if (this.lastIndex === (this.length - 1) && this.currIndex === 0) {
            this.currentSlidePosition = 0;
          } else {
            this.currentSlidePosition = (this.currIndex * 100);
          }
        }

        let transitionSpeed = 0.5;
        // If going from last to first or first to last image
        if ((this.lastIndex === (this.length - 1) && this.currIndex === 0) || (this.lastIndex === 0 && this.currIndex === (this.length - 1))) {
          transitionSpeed = 0;
        }

        // Scroll carousel to width of current slide
        for (let i = 0; i < this.carouselSlidesContainers.length; i++) {
          this.carouselSlidesContainers[i].style.transform = `translate(-${this.currentSlidePosition}%)`;
          // Set carousel speed
          this.carouselSlidesContainers[i].style.transition = `transform ${transitionSpeed}s ease-out`;
        }

      // If carousel slide container does not exist
      } else {
        this.slides[index].classList.remove('hide--collapse');
        this.slides[this.lastIndex].classList.remove('carousel__slide--current');
        this.slides[this.currIndex].classList.add('carousel__slide--current');
      }

      // Set gallery dot
      this.setDot();

      // Set last index as current index
      this.lastIndex = this.currIndex;
    }

    swipeHandler(touchStartCoord, touchEndCoord) {
      // Handles swipes based on values provided by event handlers
      const sumOfTouch = [0, 0];
      // get X offset
      sumOfTouch[0] = touchStartCoord[0] - touchEndCoord[0];
      // get Y offset
      sumOfTouch[1] = touchStartCoord[1] - touchEndCoord[1];
      return new Promise((resolve) => {
      // if X offset > Y offset
        if (Math.abs(sumOfTouch[0]) > Math.abs(sumOfTouch[1])) {
          if (sumOfTouch[0] > 30) { // if swiped further than 30px left, scroll right
            this.swiping = true;
            this.setExpectedIndex(this.getIndex(this.currIndex + 1));
            this.scrollHandler(this.getIndex(this.currIndex + 1));
          } else if (sumOfTouch[0] < -30) { // if swiped further than 30px left, scroll left
            this.swiping = true;
            this.setExpectedIndex(this.getIndex(this.currIndex - 1));
            this.scrollHandler(this.getIndex(this.currIndex - 1));
          } else {
            this.swiping = false; // false if pressed not swiped
          }
        } else {
          this.swiping = false;
        }
        resolve(this.swiping);
      });
    }

    scrollHandler(index) {
      return new Promise((resolve) => {
      // if the image is loaded, set it as current
        if (this.imageSrcs[index] === 'loaded' || this.imagesLength === 0 || index === 0) {
          resolve(this.scroll(index));
        } else if (this.imageSrcs[index] !== 'loading') {
        // otherwise wait for the image to load before displaying it and apply an overlay for loading
          this.imageSrcs[index] = 'loading';
          this.carousel.querySelector('.carousel__overlay').classList.add('carousel__overlay--visible');
          const clearOverlay = setTimeout(() => {
            this.carousel.querySelector('.carousel__overlay').classList.remove('carousel__overlay--visible');
            resolve();
          }, 3000);
          LL.preloadChildElements(this.slides[index]).then(() => {
            this.imageSrcs[index] = 'loaded';
            if (index === this.expectedIndex) { // if current index matches expected index then update displayed slide.
              this.carousel.querySelector('.carousel__overlay').classList.remove('carousel__overlay--visible');
              clearTimeout(clearOverlay);
              resolve(this.scroll(index));
            }
          });
        } else {
          this.carousel.querySelector('.carousel__overlay').classList.add('carousel__overlay--visible');
          setTimeout(() => {
            this.carousel.querySelector('.carousel__overlay').classList.remove('carousel__overlay--visible');
            resolve();
          }, 3000);
        }
      });
    }

    updateCarouselSlideExternal(index) {
      const newIndex = this.getIndex(index); // get slide index from the data-slide attribute
      // preloadSlide(newIndex);
      this.setExpectedIndex(newIndex);
      this.scrollHandler(newIndex);
    }

    // Needed for lazy loading on slow speed connections when scrolling fast, ensures that currently expected index will be displayed
    setExpectedIndex(index) {
      this.expectedIndex = index;
    }

    getIndex(index) {
      return ((index % this.length) + this.length) % this.length;
    }

    getIndexExternal() {
      return this.currIndex;
    }
  }

  function addEvents(currentcarousel) {
    const onTouchStartCord = [0, 0];
    const onTouchEndCord = [0, 0];

    if (currentcarousel.automaticTimeInterval !== 0) {
      currentcarousel.carousel.addEventListener('mouseover', () => {
        clearInterval(currentcarousel.automaticSlider);
      }, false);

      currentcarousel.carousel.addEventListener('mouseleave', () => {
        currentcarousel.runInterval(currentcarousel.currIndex + 1);
      });
    }

    if (currentcarousel.containsArrows) {
      currentcarousel.carousel.querySelector('.carousel-arrow__wrapper--left').addEventListener('mouseenter', () => {
        const newIndex = currentcarousel.getIndex(currentcarousel.currIndex - 1);
        currentcarousel.preloadSlide(newIndex);
      });

      currentcarousel.carousel.querySelector('.carousel-arrow__wrapper--right').addEventListener('mouseenter', () => {
        const newIndex = currentcarousel.getIndex(currentcarousel.currIndex + 1);
        currentcarousel.preloadSlide(newIndex);
      });

      // on left arrow click decrease index scroll through images and set active dot, set last index to current
      currentcarousel.carousel.querySelector('.carousel-arrow__wrapper--left').addEventListener('click', () => {
        // currentcarousel.loading = true;
        currentcarousel.setExpectedIndex(currentcarousel.getIndex(currentcarousel.currIndex - 1));
        currentcarousel.scrollHandler(currentcarousel.getIndex(currentcarousel.currIndex - 1));
      });

      // on right arrow click...
      currentcarousel.carousel.querySelector('.carousel-arrow__wrapper--right').addEventListener('click', () => {
        // currentcarousel.loading = true;
        currentcarousel.setExpectedIndex(currentcarousel.getIndex(currentcarousel.currIndex + 1));
        currentcarousel.scrollHandler(currentcarousel.getIndex(currentcarousel.currIndex + 1));
      });
    }

    // on a navigation dot hover...
    if (currentcarousel.containsDots && currentcarousel.dotsWrapper) {
      currentcarousel.dotsWrapper.addEventListener('mouseover', (e) => {
        if (e.target && e.target.nodeName == 'LI') {
          const newIndex = parseInt(e.target.getAttribute('data-slide'));
          currentcarousel.preloadSlide(newIndex);
        }
      });

      // on a navigation dot click...
      currentcarousel.dotsWrapper.addEventListener('click', (e) => {
        if (e.target && e.target.nodeName == 'LI') {
          const newIndex = (parseInt(e.target.getAttribute('data-slide')));
          currentcarousel.setExpectedIndex(currentcarousel.getIndex(newIndex));
          currentcarousel.scrollHandler(newIndex);
        }
      });
    }

    if (currentcarousel.carouselSlidesContainersBool) {
      for (let i = 0; i < currentcarousel.carouselSlidesContainers.length; i++) {
        // To swipe through images compare the position of the touchstart/mousedown with the position of the touchend/mouseup
        currentcarousel.carouselSlidesContainers[i].addEventListener('touchstart', (e) => {
          onTouchStartCord[0] = e.touches[0].clientX;
          onTouchStartCord[1] = e.touches[0].clientY;
          if (e.target.nodeName !== 'IMG') return;
          const newIndex = currentcarousel.getIndex(currentcarousel.currIndex + 1);
          currentcarousel.preloadSlide(newIndex);
          const newIndex2 = currentcarousel.getIndex(currentcarousel.currIndex - 1);
          currentcarousel.preloadSlide(newIndex2);
        });

        currentcarousel.carouselSlidesContainers[i].addEventListener('touchend', (e) => {
          onTouchEndCord[0] = e.changedTouches[0].clientX;
          onTouchEndCord[1] = e.changedTouches[0].clientY;
          currentcarousel.swipeHandler(onTouchStartCord, onTouchEndCord)
            .then((res) => {
              currentcarousel.swiping = res;
            });
        });

        currentcarousel.carouselSlidesContainers[i].addEventListener('click', (e) => {
          if (currentcarousel.swiping) {
            e.preventDefault();
            e.stopPropagation();
          }
        });

        currentcarousel.carouselSlidesContainers[i].addEventListener('mousedown', (e) => {
          onTouchStartCord[0] = e.clientX;
          onTouchStartCord[1] = e.clientY;
          // currentcarousel.swiping = true;
          if (e.target.nodeName !== 'IMG') return;
          const newIndex = currentcarousel.getIndex(currentcarousel.currIndex + 1);
          currentcarousel.preloadSlide(newIndex);
          const newIndex2 = currentcarousel.getIndex(currentcarousel.currIndex - 1);
          currentcarousel.preloadSlide(newIndex2);
        });

        currentcarousel.carouselSlidesContainers[i].addEventListener('mouseup', (e) => {
          onTouchEndCord[0] = e.clientX;
          onTouchEndCord[1] = e.clientY;

          currentcarousel.swipeHandler(onTouchStartCord, onTouchEndCord)
            .then((res) => {
              currentcarousel.swiping = res;
            });
        });
      }
    } else {
      // To swipe through images compare the position of the touchstart/mousedown with the position of the touchend/mouseup
      currentcarousel.carousel.addEventListener('touchstart', (e) => {
        onTouchStartCord[0] = e.touches[0].clientX;
        onTouchStartCord[1] = e.touches[0].clientY;
        if (e.target.nodeName !== 'IMG') return;
        const newIndex = currentcarousel.getIndex(currentcarousel.currIndex + 1);
        currentcarousel.preloadSlide(newIndex);
        const newIndex2 = currentcarousel.getIndex(currentcarousel.currIndex - 1);
        currentcarousel.preloadSlide(newIndex2);
      });

      currentcarousel.carousel.addEventListener('touchend', (e) => {
        onTouchEndCord[0] = e.changedTouches[0].clientX;
        onTouchEndCord[1] = e.changedTouches[0].clientY;
        currentcarousel.swipeHandler(onTouchStartCord, onTouchEndCord)
          .then((res) => {
            currentcarousel.swiping = res;
          });
      });

      currentcarousel.carousel.addEventListener('click', (e) => {
        if (currentcarousel.swiping) {
          e.preventDefault();
          e.stopPropagation();
        }
      });

      currentcarousel.carousel.addEventListener('mousedown', (e) => {
        onTouchStartCord[0] = e.clientX;
        onTouchStartCord[1] = e.clientY;
        // currentcarousel.swiping = true;
        if (e.target.nodeName !== 'IMG') return;
        const newIndex = currentcarousel.getIndex(currentcarousel.currIndex + 1);
        currentcarousel.preloadSlide(newIndex);
        const newIndex2 = currentcarousel.getIndex(currentcarousel.currIndex - 1);
        currentcarousel.preloadSlide(newIndex2);
      });

      currentcarousel.carousel.addEventListener('mouseup', (e) => {
        onTouchEndCord[0] = e.clientX;
        onTouchEndCord[1] = e.clientY;

        currentcarousel.swipeHandler(onTouchStartCord, onTouchEndCord)
          .then((res) => {
            currentcarousel.swiping = res;
          });
      });
    }
  }

  // Video billboard(deprecating fancy box)
  setTimeout(() => {
    const billboard = [].slice.call(document.getElementsByClassName('carousel__slide'));
    for (let i = 0; i < billboard.length; i++) {
      if (billboard[i].children[0].host === 'www.youtube.com') {
        document.getElementById('cdvideo').src = billboard[i].children[0].href;
        billboard[i].children[0].classList.add('modal__trigger');
        billboard[i].children[0].setAttribute('data-modal', 'modal-billboard');
        ModalEffects.addModalTrigger(billboard[i].children[0], pausePlayer);
        billboard[i].children[0].removeAttribute('href');
        billboard[i].children[0].removeAttribute('target');
      }
    }
  }, 300);

  function pausePlayer() {
    const x = document.getElementById('cdvideo');
    x.src += 'pause';
    const y = x.src;
    const q = y.replace('pause', '');
    x.src = q;
  }


  // initialising carousel and scroller object (e.g. VDP gallery)
  function initVDC(carouselWithScroller) {
    const gallery = carouselWithScroller.querySelector('.carousel--vdp');
    return new Carousel(gallery, false);
  }

  // Loading all billboards and adding events
  vm.onload(() => {
    const galleries = document.querySelectorAll('.carousel--billboard');
    let index = 0;
    for (let i = 0; i < galleries.length; i++) {
      galleryObjs.push(new Carousel(galleries[i]));
      if (galleryObjs[index].length > 1) {
        addEvents(galleryObjs[index]);
      }
      index++;
    }

    const automaticGalleries = document.querySelectorAll('.carousel--automatic');
    for (let i = 0; i < automaticGalleries.length; i++) {
      galleryObjs.push(new Carousel(automaticGalleries[i], false, false, 2000));
      if (galleryObjs[index].length > 1) {
        addEvents(galleryObjs[index]);
      }
      index++;
    }

    const vdps = document.querySelectorAll('.carousel--vdp-no-thumbnails');
    for (let i = 0; i < vdps.length; i++) {
      initModal(vdps[i], index);
      galleryObjs.push(new Carousel(vdps[i], false));
      if (galleryObjs[index].length > 1) {
        addEvents(galleryObjs[index]);
      }
      index++;
    }

    const testimonials = document.querySelectorAll('.customer-testimonial.js-carousel');
    for (let i = 0; i < testimonials.length; i++) {
      galleryObjs.push(new Carousel(testimonials[i]));
      if (galleryObjs[index].length > 1) {
        addEvents(galleryObjs[index]);
      }
      index++;
    }
  });

  window.carouselImageObjs = galleryObjs;
  window.initVDC = initVDC;
}(window, document));
